import React, { useRef, useEffect, forwardRef, useState } from 'react';
import { motion } from 'framer-motion';
import swipeForBars from './swipeForBars';
import swipeDetect from './swipeDetect';
import useSwipeForBars from './useSwipeForBars';
import { videoActions } from '../store/video';
import { useDispatch, useSelector } from 'react-redux';
import './Bar.css';
import clickDragOverBars from './clickDragOverBars';
import useDragSelection from '../hooks/useDragSelection';
import { createAndAddElement } from './video_components/Control';
import isEqual from 'lodash/isEqual';
import useLongPress from '../hooks/useLongPress';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { tourActions } from '../store/tour';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

let count = 0;
// let dragElements = {};

const Bar = ({
  id,
  start,
  countLabel,
  label,
  handleClick,
  index,
  originalIndex,
  notes,
  sectionListRef,
  // currentBar,
  isCurrent,
  // selectionExists,
  isSelected,
  isFirst,
  isLast,
  setBarToRename,
  // selectionIndices,
  isInASelection,
  isInASelectionFirst,
  isInASelectionLast,
  savedSelection,
  // onTreeVideoClickHandler,
}) => {
  // console.log('selectionIndices fuck');
  // console.log({ selectionIndices });
  // console.log(notes);
  const dispatch = useDispatch();
  const barRef = useRef(null);
  const dragButtonRef = useRef(null);
  const [userRole, isMobile] = useSelector(
    (state) => [state.auth.user.role, state.settings.isMobile],
    isEqual
  );

  console.log('🍫 bar rerender: ', count++, originalIndex);

  const dragElements = useDragSelection(
    dragButtonRef.current,
    originalIndex,
    (value) => {
      console.log('current dragged to bar: ', value);
      dispatch(videoActions.updateFromDragEvents(value));
      dispatch(tourActions.handleLinkedAction('dragButtonBar'));
    },
    sectionListRef.current
  );
  let isHighlighted = isCurrent;
  // const dragElementsActive = !isMobile ? {} : dragElements;
  const dragElementsActive = false ? {} : dragElements;
  // const projectedCurrent =

  const clickHandler = () => {
    handleClick(id, start, originalIndex);
  };

  const longPressHandler = () => {
    if (userRole === 'admin') {
      setBarToRename({ index: originalIndex, label });
    }
    // console.log('longpress motherfucker');
  };

  const defaultOptions = {
    // shouldPreventDefault: false,
    delay: 300,
  };

  const longPressEvent = useLongPress(
    longPressHandler,
    clickHandler,
    defaultOptions
  );

  // useEffect(() => {
  //   function preventBehavior(e) {
  //     e.preventDefault();
  //   }

  //   if (originalIndex < 2) {
  //     barRef.current.addEventListener('touchmove', preventBehavior, {
  //       passive: false,
  //     });
  //   }
  // }, []);

  return (
    <motion.li
      //   ref={ref}
      ref={barRef}
      layout
      //   layoutId={`count-holder${index}`}
      initial={{ scale: 1, opacity: 0 }}
      //   initial={{ scale: 1.8, opacity: 1 }}
      animate={{ scale: 1, opacity: 1 }}
      // exit={{ scale: 1, opacity: 1, y: -index * 50 }}
      className={`section-bar section-bar-${originalIndex} prevent-select`}
      transition={{ type: 'spring' }}
      key={id}
      id={id}
      {...longPressEvent}
      // onClick={(e) => {
      //   clickHandler();
      // }}
      style={{ padding: '0 5px' }}
    >
      {/* {isCurrent && (
        <motion.div
          layoutId="tracking-play-triangle"
          style={{
            position: 'absolute',
            left: '60px',
            top: '0px',
            // transform: 'translateY(-50%)',
            color: 'white',
            zIndex: 30,
            fontSize: '40px',
          }}
        >
          ●
        </motion.div>
      )} */}
      <motion.div
        // ref={barRef}
        // layout
        // key={id + 'shapee'}
        animate={{
          backgroundColor: isSelected
            ? 'animate-color-to-selected'
            : 'animate-color-from-selected', // backgroundColor,
          // backgroundColor: isSelected ? 'var(--bar-background-color)' : '#7b2cbf00', // backgroundColor,
          borderColor: isSelected ? 'var(--bar-background-color)' : 'white', // backgroundColor,
        }}
        transition={{ duration: 0.5 }}
        className={`bar-shape ${
          countLabel === '1234'
            ? 'count1234'
            : countLabel === '5678'
            ? 'count5678'
            : 'noLabel'
        } ${isInASelection ? 'selectionBar' : ''} ${
          isInASelectionFirst ? 'selectionBarFirst' : ''
        } ${isInASelection ? 'selectionBar' : ''} ${
          isInASelectionLast ? 'selectionBarLast' : ''
        }
    
        `}
        style={{
          background: isSelected
            ? `${
                false
                  ? 'var(--main-purple-no-transparancy)'
                  : 'var(--bar-background-color)'
              }`
            : `${isInASelection ? '#0F717377' : '#00000077'}`, //backgroundColor,
          // backgroundColor: isSelected ? 'var(--bar-background-color)' : '#7b2cbf00', //backgroundColor,
          borderColor: isSelected ? 'var(--bar-background-color)' : 'white', // backgroundColor,

          // height: '35px',
        }}
      >
        {isMobile && (
          <div
            ref={dragButtonRef}
            className={`circle-for-first-and-last drag-circle-${originalIndex}`}
            {...dragElementsActive}
            style={{
              pointerEvents: `${isFirst || isLast ? 'auto' : 'none'}`,
            }}
          >
            <div
              className="drag-symbol-holder"
              style={{
                display: isFirst || isLast ? 'block' : 'none',
              }}
            >
              <div className="drag-symbol-background"></div>
              <div className="drag-arrow" style={{ height: '10px' }}>
                {isFirst ? (
                  <KeyboardArrowUpIcon style={{ pointerEvents: 'none' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ pointerEvents: 'none' }} />
                )}
              </div>

              <div className="drag-arrow" style={{ height: '10px' }}>
                {isLast ? (
                  <KeyboardArrowDownIcon style={{ pointerEvents: 'none' }} />
                ) : (
                  <KeyboardArrowUpIcon style={{ pointerEvents: 'none' }} />
                )}
              </div>
              {/* <div className="drag-arrow" style={{ height: '10px' }}>
                {isInASelectionFirst ? (
                  <KeyboardArrowDownIcon style={{ pointerEvents: 'none' }} />
                ) : (
                  <KeyboardArrowUpIcon style={{ pointerEvents: 'none' }} />
                )}
              </div> */}
            </div>
          </div>
        )}
        {!isSelected && isInASelectionFirst && (
          <div
            className={`circle-for-first-and-last`}
            // onClick={() => {
            //   dispatch(
            //     videoActions.updateSelectedSections({
            //       value: originalIndex,
            //       mouseEvent: true,
            //     })
            //   );
            // }}

            style={{ color: 'white', pointerEvents: 'auto' }}
          >
            {/* <div>+</div> */}
            <PlayArrowIcon
              style={{ pointerEvents: 'auto' }}
              onMouseDown={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
              onMouseMove={(e) => e.stopPropagation()}
              onTouchMove={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              onTouchEnd={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                // alert('ff');
                console.log('fuck');
                console.log({ savedSelection });
                const sections = savedSelection.sections;
                const looptime = savedSelection.looptime;
                dispatch(
                  videoActions.updateSelectedSectionsSimple({
                    sections,
                    looptime,
                    // practiceIndex,
                  })
                );
              }}
            />
          </div>
        )}
        {!isMobile && (
          <div
            className={`add-to-selection circle-for-first-and-last`}
            onClick={() => {
              dispatch(
                videoActions.updateSelectedSections({
                  value: originalIndex,
                  mouseEvent: true,
                })
              );
            }}
            // // style={{ color: 'white' }}
          >
            <div>+</div>
          </div>
        )}
        <div
          className={`icon-and-text-flex-container  ${
            isFirst || isLast || isInASelectionFirst ? 'fade-font-box' : ''
          }`}
        >
          {notes ? (
            <div style={{ position: 'relative' }}>
              {' '}
              <TextSnippetOutlinedIcon
                // className="invert-pic"
                // src={`${cloudfrontPath}/notes.svg`}
                style={{
                  color: 'white',
                  width: '18px',
                  transform: 'scaleY(1.3)',
                  // zIndex: 1600,
                  // pointerEvents: 'auto',
                }}
                // onTouchStart={() => {
                //   alert('onTocuh of child');
                // }}
                onMouseDown={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
                onMouseMove={(e) => e.stopPropagation()}
                onTouchMove={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onTouchEnd={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  // e.preventDefault();
                  // alert('you hit it ');
                  // console.log('adsfjsdkfsdjfkl');
                  createAndAddElement(
                    document.getElementById('captions-container'),
                    index,
                    notes,
                    true
                  );
                }}
              />
              {/* <TextSnippetOutlinedIcon
                className={` note-icon hidden-w-shadow ${
                  isCurrent ? 'is-selected pulsate' : ''
                }`}
                src={`${cloudfrontPath}/notes.svg`}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: '0px',
                  color: 'white',
                  width: '18px',
                  // zIndex: 1600,
                  pointerEvents: 'none',
                  filter:
                    'drop-shadow(0 0 5px #fff, 0 0 15px #fff, 0 0 20px black, 0 0 40px black, 0 0 75px black)',
                }}
              /> */}
            </div>
          ) : (
            <div className="extra-to-center-text-w-note-icon"></div>
          )}
          <div
            style={{
              position: 'relative',
              flexGrow: 1,
              flexBasis: 'auto',
              // height: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <div
              className={`font-box fadeInOut ${isCurrent ? 'pulsate' : ''}`}
              style={{ backgroundColor: 'none' }}
              onMouseDown={() => false}
            >
              {label}
            </div>
            <div style={{ position: 'absolute' }}>
              <div
                className={`font-box hidden-w-shadow ${
                  isCurrent ? 'is-selected pulsate' : ''
                }`}
                isHighlighted={String(isHighlighted)}
                onMouseDown={() => {
                  return false;
                }}
                // style={{ backgroundColor: 'none' }}
              >
                {label}
              </div>
            </div>
          </div>
          <div className="extra-to-center-text-w-note-icon"></div>
        </div>
      </motion.div>
    </motion.li>
  );
};

const MotionBar = motion(Bar);
export default React.memo(MotionBar);
