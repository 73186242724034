import './VideoContainer.css';
import ReactPlayer from 'react-player';
import Control from './Control';
import { useState, useRef, useEffect, useCallback } from 'react';
import { formatTime } from './format';
import { motion, AnimatePresence, m } from 'framer-motion';
import determineClickCountFactory from '../determineClickCount';
import TrackingBall from '../TrackingBall';
import { useDispatch, useSelector } from 'react-redux';
import video, { videoActions } from '../../store/video';
import { friendActions } from '../../store/friend';
import CircleLoader from '../Loaders/CircleLoader';
import { useColor } from 'color-thief-react';
import { useTour } from '@reactour/tour';

import useImageColor from 'use-image-color';
import Hls from 'hls.js';
import { tourActions } from '../../store/tour';
import Popup from './Popup';

import ReactDOM from 'react-dom';
import { authActions } from '../../store/auth';
import isEqual from 'lodash/isEqual';

const determineClickCount = determineClickCountFactory();
const portalElement = document.getElementById('video');

let count = 0;
const progressInterval = 1000;
const displayControlTime = 1;

function VideoContainer({
  videoData,
  addSectionHandler,
  userId,
  onModalClose,
  initialLooptime,
  onHomeClick,
  // setSearchArray,
}) {
  // sections: each individual 4 count
  // selection: multiple sections that were just selected
  // savedSelections: selections that has been saved

  const dispatch = useDispatch();
  // const { setIsOpen } = useTour();
  useEffect(() => {
    return () => {
      dispatch(videoActions.updateRecentVideos(videoData));
      dispatch(authActions.updateNewVideos(videoData.id));
    };
  }, []);

  useEffect(() => {
    if (isAdmin) {
      // setIsOpen(true);
      // dispatch(tourActions.doubleClickAnimation());
    }
    function preventBehavior(e) {
      e.preventDefault();
    }

    // document.addEventListener('touchmove', preventBehavior, { passive: false });
  }, []);

  const [
    friends,
    isAdmin,
    currentStep,
    currentTour,
    activeTour,
    showWelcome,
    isMobile,
    allSavedSelections,
  ] = useSelector(
    (state) => [
      state.friend.friends,
      state.auth.user.role === 'admin',
      state.tour.currentStep,
      state.tour.currentTour,
      state.tour.active,
      state.tour.showWelcome,
      state.settings.isMobile,
      state.video.allSavedSelections,
      // state.auth.user.friendTag,
    ]
    // isEqual
  );

  const [selectionIndices, setSelectionIndices] = useState({});
  // sectionIndices
  // {all, first, last,}

  useEffect(() => {
    // console.log({ videoData });
    console.log('selection finder');
    const toBeSavedSelections = { all: [], first: [], last: [], section: [] };
    const selections = allSavedSelections.filter(
      (selection) => selection.video.id == videoData.id
    );

    selections.forEach((selection, index) => {
      console.log('selection: ', selection);
      toBeSavedSelections.all.push(...selection.sections);
      toBeSavedSelections.first.push(selection.sections[0]);
      if (selection.sections.length > 1) {
        toBeSavedSelections.last.push(
          selection.sections[selection.sections.length - 1]
        );
      }
      toBeSavedSelections.section.push(selection);
    });
    setSelectionIndices(toBeSavedSelections);
    console.log({ toBeSavedSelections });
    console.log('doublefuck');
    console.log({ selectionIndices });
  }, []);

  const delayFix = 0.35;
  const [showTour, setShowTour] = useState(false);
  const [fullscreen, setFullscreen] = useState(() => {
    const devMode = process.env.REACT_APP_ENV === 'dev';
    console.log('dev mode? : ', process.env.REACT_APP_ENV);
    return !devMode && isMobile;
  });

  useEffect(() => {
    return () => {
      dispatch(tourActions.close());
    };
  }, []);

  const sectionTimeArray = useRef([
    ...videoData.sections.map(
      (section) => section.fourCountTimes[0] - delayFix
    ),
    1000,
  ]);
  const outerContainerRef = useRef(null);

  const [isSelectedArray, setIsSelectedArray] = useState([]);

  console.log('😎 video container rerender');

  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const firstLoad = useRef(true);

  const countForBackdrop = useRef(null);
  // const scrollTopStored = useRef(0);
  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [annotations, setAnnotations] = useState(null);
  const [currentAnnotations, setCurrentAnnotations] = useState(null);
  const [allSectsWNotes, setAllSectionsWNotes] = useState(null);
  const [previousAnnotations, setPreviousAnnotations] = useState(null);
  // const [currSectionBar, setCurrSectionBar] = useState(0);

  const slowmoCoords = useRef([]);

  // const [selectedSections, setSelectedSections] = useState([]);
  const [looptime, selectedSections, currentBar] = useSelector((state) => [
    state.video.looptime,
    state.video.selectedSections,
    state.video.currentBar,
    // state.auth.user._id,
  ]);

  // console.log('show me selectedSections?.length: ', selectedSections?.length);
  const HLSConfig = {
    forceKeyFrameOnDiscontinuity: false,
    maxBufferLength: 30, // for example, set to 30 seconds
    maxMaxBufferLength: selectedSections?.length > 0 ? 2.5 : 30, // hard upper limit for maxBufferLength. This does not seem to update in the reactPlayer even when HLSConfig changes
    // Set a maximum buffer size to control the amount of data preloaded.
    maxBufferSize: 6 * 1024 * 1024, // for example, set to 10MB
  };

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  useEffect(() => {
    if (!isIOS && Hls.isSupported()) {
      // alert('hls is supported');
      const hls = new Hls(HLSConfig);
      hls.attachMedia(videoPlayerRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoPlayerRef.current.play();
      });
    }
  }, [videoPlayerRef.current]);

  // console.log('show me HLSConfig: ', HLSConfig);
  const onReady = useCallback(() => {
    // console.log('onReady');
    // console.log('onReady');
    // console.log('onReady');
    if (firstLoad.current) {
      firstLoad.current = false;
      videoPlayerRef.current.seekTo(
        Math.max(looptime[0] - delayFix, 0),
        'seconds'
      );
    }
  }, [videoPlayerRef.current]);

  const onPlay = () => {
    console.log('onPlay');
    if (activeTour && !showTour && isMobile) {
      // if (activeTour && !showTour && isAdmin) {
      setTimeout(
        () => {
          console.log('onPlay callback');
          // alert('setShowTriggersd');
          setShowTour(true);
        },
        showWelcome ? 4000 : 0
      );
    }
  };

  const { colors } = useImageColor(videoData.thumbnail, {
    cors: true,
    colors: 5,
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      `--dominant-colors-1`,
      videoData?.dominantColors?.at(0) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-2`,
      videoData?.dominantColors?.at(1) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-3`,
      videoData?.dominantColors?.at(2) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-4`,
      videoData?.dominantColors?.at(3) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-5`,
      videoData?.dominantColors?.at(4) || `#000000`
    );
    console.log('fucking color for real? ', colors);
    if (colors) {
      document.documentElement.style.setProperty(
        `--dominant-colors-1`,
        colors[0]
      );
      document.documentElement.style.setProperty(
        `--dominant-colors-2`,
        colors[1]
      );
      document.documentElement.style.setProperty(
        `--dominant-colors-3`,
        colors[2]
      );
      document.documentElement.style.setProperty(
        `--dominant-colors-4`,
        colors[3]
      );
    }
  }, [colors]);

  const retrieveSections = async () => {
    const responseSections = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/sections/user/${userId}/video/${videoData._id}`,
      {
        credentials: 'include',
      }
    );
    const dataSection = await responseSections.json();
    // console.log('😊 dataSection');
    // console.log(dataSection.data);
    setAnnotations(dataSection.data);

    const justViewed = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/users/justViewed/${videoData._id}`,
      {
        credentials: 'include', // include, *same-origin, omit
      }
    );
    const viewResponse = await justViewed.json();

    const userVideoData = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/userVideoData`,
      {
        method: 'POST',

        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: userId,
          video: videoData.id,
          sectionsLength: videoData.sections.length,
          // password: enteredPassword,
        }),
      }
    );
    console.log('before the if fail check');
    console.log(userVideoData);
    // dispatch(vi)klad
    if (!userVideoData.ok) {
      console.log('in failed if while obtaining video user data');
      alert('failed retreiving userVideoData');
    } else {
      const dataUVD = await userVideoData.json();
      console.log('🎅 dataUVD.data.user');
      console.log(dataUVD.data);
      // setNotes(dataUVD.data.sections.map((section) => section?.notes));
      // alert(JSON.stringify(data));
      dispatch(
        videoActions.initialNotesArray(
          dataUVD.data.sections.map((section) => section?.notes)
        )
      );
      dispatch(videoActions.userVideoDataUpdate(dataUVD.data));
      // dispatch(authActions.login(dataUVD.data.user));
    }
    // console.log(viewResponse);

    // const friendResponse = await fetch(
    //   `http://127.0.0.1:8000/api/v1/users/${userId}/video/${videoData._id}/findfriends`,
    //   {
    //     credentials: 'include',
    //   }
    // );
    // const friendData = await friendResponse.json();
    // console.log('🕺🏻friendData');
    // console.log(friendData);
    // dispatch(friendActions.updateFriends(friendData.data.friendsUpdated));
    // console.log('😊 dataSection');
    // console.log(dataSection.data);
    // setAnnotations(dataSection.data);
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const enterFullscreen = () => {
    // if (isIOS) return;
    const elem = outerContainerRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };
  useEffect(() => {
    const devMode = process.env.REACT_APP_ENV === 'dev';
    if (fullscreen) {
      enterFullscreen();
    } else if (document.fullscreenElement || document.webkitFullscreenElement) {
      exitFullscreen();
    }
    return () => {
      // if (!devMode && isMobile) {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        exitFullscreen();
      }

      // }
      // }
    };
  }, [fullscreen]);

  useEffect(() => {
    dispatch(friendActions.sortFriends(videoData._id));
  }, []);

  useEffect(() => {
    retrieveSections();
  }, []);

  useEffect(() => {
    return;
    // if (!showSectionBarList) return;
    // if (selectedSections.length > 0) return;

    // let currentSectionIndex = currSectionBar;
    // const totalSections = videoData.sections.length;

    // const sectionHeight = 45;
    // const buffer = 140;
    // const scrollHeight = 500;

    // const currentScrollVal = sectionListRef.current.scrollTop;

    // if (
    //   (currentSectionIndex + 1) * sectionHeight + buffer >
    //   scrollHeight + currentScrollVal
    // ) {
    //   sectionListRef.current.scrollTop =
    //     currentScrollVal + scrollHeight - buffer;
    // } else if (
    //   (currentSectionIndex + 1) * sectionHeight - 30 <
    //   currentScrollVal
    // ) {
    //   sectionListRef.current.scrollTop = Math.max(
    //     0,
    //     currentScrollVal - (scrollHeight + buffer)
    //   );
    // }
  });

  const [videoState, setVideoState] = useState({
    playing: true,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });

  //Destructuring the properties from the videoState
  const { playing, muted, volume, playbackRate, played, seeking, buffer } =
    videoState;

  // console.log('plaued: ', played);
  let currentTime = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : '00:00';
  const duration = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : '00:00';

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  const timePadding = 0; //0.15;

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
  };

  const handleFastFoward = () => {
    //FastFowards the video player by adding 10
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
  };

  // const seekToTime = (time) => {
  //   videoPlayerRef.current.seekTo(time);
  // };

  const seekToTime = useCallback((time) => {
    videoPlayerRef.current.seekTo(time);
  }, []);

  // console.log('VIDEO STAATE');
  // console.log(videoState);
  //console.log("========", (controlRef.current.style.visibility = "false"));
  const progressHandler = (state) => {
    console.log('🦹🏽‍♀️ on progress');
    console.log(videoState);
    countForBackdrop.current++;

    currentTime = videoPlayerRef.current.getCurrentTime();
    let barPointer = currentBar;
    // we want
    // console.log('sectiontime array.current: ', sectionTimeArray.current);
    while (sectionTimeArray.current[barPointer] > currentTime) {
      barPointer--;
    }
    while (sectionTimeArray.current[barPointer + 1] < currentTime) {
      barPointer++;
    }

    // console.log('actual position is: ', barPointer);
    if (!seeking) {
      if (barPointer !== currentBar)
        dispatch(videoActions.updateCurrentBar(barPointer));
      // console.log(
      //   'progresshandle currentsection data: ',
      //   videoData.sections[currentBar]
      // );
      // const  = videoData.sections[currentBar].fourCountTimes
      // if(videoData.sections[currentBar])

      if (
        (!seeking &&
          currentTime != 0 &&
          currentTime > looptime[1] + timePadding) ||
        currentTime < looptime[0] - timePadding
      ) {
        // console.log('in if loop');
        videoPlayerRef.current.seekTo(looptime[0]);
      }

      if (slowmoCoords.current.length === 0) {
        setVideoState({ ...videoState, ...state });
        return;
      }
      let rate = 1;
      const height = 150;

      const loopDuration = looptime[1] - looptime[0];
      const percentageComplete = (currentTime - looptime[0]) / loopDuration;
      // console.log('slowmoCoords.current.length: ', slowmoCoords.current.length);
      // console.log('percentageComplete: ', percentageComplete);
      if (slowmoCoords.current.length <= 2) {
        rate = 1;
      } else {
        const firstX = slowmoCoords.current[0].x;
        const lastX = slowmoCoords.current[slowmoCoords.current.length - 1].x;
        const width = lastX - firstX;
        for (let i = 0; i < slowmoCoords.current.length; i++) {
          if (
            percentageComplete <
            (slowmoCoords.current[i].x - firstX) / width
          ) {
            const rateMultiplier = 1;
            rate =
              (2 - (slowmoCoords.current[i].y * 2) / height) * rateMultiplier;
            break;
          }
        }
      }

      setVideoState({ ...videoState, ...state, playbackRate: rate });
    }
  };

  const handleNewCoord = (coord) => {
    const lastXCoord =
      slowmoCoords.current[slowmoCoords.current.length - 1]?.x || 0;
    if (slowmoCoords.current.length === 0 || coord.x - lastXCoord > 10) {
      slowmoCoords.current.push(coord);
    }
    const firstXCoord = slowmoCoords.current[0].x;
    setVideoState({
      ...videoState,
      playing: false,
      // played: parseFloat(value / 100),
    });
    const loopDuration = looptime[1] - looptime[0];

    videoPlayerRef.current.seekTo(
      ((coord.x - firstXCoord) / (300 - firstXCoord)) * loopDuration +
        looptime[0]
    );
  };

  const handleClearCoords = () => {
    slowmoCoords.current = new Array();
    setVideoState({
      ...videoState,
      playbackRate: 1.0,
    });
  };

  const handleSetPlayback = (e, value) => {
    // playbackRate(parseFloat(value / 100).toFixed(1));
    // slowmoCoords.current = new Array();
    setVideoState({
      ...videoState,
      playbackRate: parseFloat(value / 100).toFixed(1),
    });
  };

  const seekHandler = (e, value, scaleIncrement = 1) => {
    // console.log()
    setVideoState({
      ...videoState,
      playing: false,
      played: parseFloat(value / (100 * scaleIncrement)),
    });
    videoPlayerRef.current.seekTo(parseFloat(value / (100 * scaleIncrement)));
  };

  //  const seekHandlerSeconds = (e, value) => {
  //   // console.log()
  //   setVideoState({
  //     ...videoState,
  //     playing: false,
  //     played: parseFloat(value / (100)),
  //   });
  //   videoPlayerRef.current.seekTo(parseFloat(value / (100 * scaleIncrement)));
  // };

  const seekMouseUpHandler = (e, value) => {
    console.log(value);

    setVideoState({ ...videoState, playing: true, seeking: false });
    videoPlayerRef.current.seekTo(value / 100);
  };
  const seekMouseUpMoreOptions = (e, value, scaleIncrement = 1) => {
    console.log(value);

    setVideoState({ ...videoState, playing: false, seeking: false });
    videoPlayerRef.current.seekTo(value / (100 * scaleIncrement));
  };

  const volumeChangeHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    });
  };

  const volumeSeekUpHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    });
  };

  const muteHandler = () => {
    //Mutes the video player
    setVideoState({ ...videoState, muted: !videoState.muted });
  };

  const onSeekMouseDownHandler = (e) => {
    setVideoState({ ...videoState, seeking: true });
  };
  const onSeekMouseDownHandlerMoreOptions = (e, value, scale) => {
    videoPlayerRef.current.seekTo(value / (100 * scale));
    setVideoState({ ...videoState, seeking: true });
  };

  const mouseMoveHandler = () => {
    controlRef.current.style.visibility = 'visible';
    count = 0;
  };

  const bufferStartHandler = () => {
    console.log('Bufering.......');
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    console.log('buffering stoped ,,,,,,play');
    setVideoState({ ...videoState, buffer: false });
  };

  // const Backdrop = ({ onClose }) => {
  //   return <div className={classes.backdrop} onClick={onClose} />;
  // };

  return (
    <>
      <motion.div
        ref={outerContainerRef}
        className="outermost-container-for-real"
        style={{
          position: 'fixed',
          bottom: '0',
          top: '0',
          right: '0',
          left: '0',
          zIndex: 600,
        }}
      >
        <AnimatePresence>
          {showTour && currentTour && (
            <Popup
              currentStep={currentStep}
              totalSteps={currentTour?.steps?.length}
              title={currentTour?.steps?.at(currentStep)?.title}
              text={currentTour?.steps?.at(currentStep)?.text}
              onClose={() => {
                setShowTour(false);
                // dispatch(tourActions.close());
              }}
              dispatch={dispatch}
            />
          )}
        </AnimatePresence>
        {true && (
          <div
            className={`player__wrapper ${
              flipHorizontal ? 'flip-horizontal' : ''
            }`}
            onMouseMove={mouseMoveHandler}
            // style={{ transform: [{ scaleX: -1 }] }}
          >
            {/* <div style={{height: 'f'}}> */}{' '}
            {buffer && (
              <>
                <CircleLoader />
                {/* <div className="blurry-loading-image" /> */}
              </>
            )}
            {/* {buffer && <CircleLoader />} */}
            <ReactPlayer
              ref={videoPlayerRef}
              className="player"
              url={`${videoData.url}/filename.m3u8`}
              loop
              // pip={false}
              // url="https://d2qxuoym2zs537.cloudfront.net/hhh3/filename.m3u8"
              // url="https://d2qxuoym2zs537.cloudfront.net/videos/filename.m3u8"
              // url="https://d2qxuoym2zs537.cloudfront.net/videos/f5c4698c23d1e6604b65a932f87a332607ff4a2429166edac3de0045ab5e02dc"
              // config={{
              //   // hlsOptions: hlsConfig,
              //   // file: { forceVideo: true },
              //   file: true
              //     ? {
              //         forceHLS: true,
              //         hlsOptions: HLSConfig,
              //         // hlsVersion: Hls.version,
              //         // hlsjs: Hls,
              //       }
              //     : {
              //         forceHLS: true,
              //         hlsOptions: HLSConfig,
              //         hlsVersion: Hls.version,
              //         hlsjs: Hls,
              //       },
              // }}
              width="100%"
              height="100%"
              playing={playing}
              volume={volume}
              muted={muted}
              preload="auto"
              playsinline={isIOS ? true : false}
              // muted={true}
              onProgress={progressHandler}
              onBuffer={bufferStartHandler}
              onBufferEnd={bufferEndHandler}
              progressInterval={progressInterval}
              playbackRate={playbackRate}
              onReady={onReady}
              onPlay={onPlay}
            />
            {/* </div> */}
          </div>
        )}

        <Control
          controlRef={controlRef}
          onPlayPause={playPauseHandler}
          playing={playing}
          onRewind={rewindHandler}
          onForward={handleFastFoward}
          played={played}
          onSeek={seekHandler}
          onSeekMouseUp={seekMouseUpHandler}
          onSeekMouseUpMoreOptions={seekMouseUpMoreOptions}
          volume={volume}
          onVolumeChangeHandler={volumeChangeHandler}
          onVolumeSeekUp={volumeSeekUpHandler}
          mute={muted}
          onMute={muteHandler}
          playRate={playbackRate}
          durationFormatted={formatDuration}
          duration={duration}
          // durationRaw
          currentTime={formatCurrentTime}
          onMouseSeekDown={onSeekMouseDownHandler}
          onMousSeekDownHandlerMoreOptions={onSeekMouseDownHandlerMoreOptions}
          allSectsWNotes={allSectsWNotes}
          videoData={videoData}
          isVariableSlowMo={slowmoCoords?.current?.length > 1}
          // showVisibleSpeed={showVisibleSpeed}
          // setShowVisibleSpeed={setShowVisibleSpeed}
          playbackRate={playbackRate}
          handleSetPlayback={handleSetPlayback}
          handleClearCoords={handleClearCoords}
          videoPlayerRef={videoPlayerRef}
          seekToTime={seekToTime}
          selectedSections={selectedSections}
          addSectionHandler={addSectionHandler}
          // setSearchArray={setSearchArray}
          currentBar={currentBar}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          isIOS={isIOS}
          flipHorizontal={flipHorizontal}
          setFlipHorizontal={setFlipHorizontal}
          // handleClearCoords={handleClearCoords}
          variableSpeedComponent={
            <TrackingBall
              onNewCoord={handleNewCoord}
              onClearCoords={handleClearCoords}
              onPlayPause={playPauseHandler}
              slowmoCoords={
                slowmoCoords.current.length > 0 ? slowmoCoords.current : null
              }
              // setShowVisibleSpeed={setShowVisibleSpeed}
            />
          }
          onHomeClick={onHomeClick}
          selectionIndices={selectionIndices}

          // playPauseHandler={playPauseHandler}
        />

        <motion.ul
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1, y: '-20px' }}
          // transition={{ duration: 1 }}
          style={{
            position: 'absolute',
            top: '70vh',
            textAlign: 'center',
            width: '100%',
            paddingLeft: '10%',
            paddingRight: '10%',
            zIndex: 90,
            listStyleType: 'none',
          }}
        >
          <AnimatePresence>
            {[
              ...new Set([
                ...(Array.isArray(previousAnnotations)
                  ? previousAnnotations
                  : []),
                ...(Array.isArray(currentAnnotations)
                  ? currentAnnotations
                  : []),
              ]),
            ].map((el, index) => (
              <li
                layout
                key={el}
                initial={{ scaleY: 0.5, opacity: 0 }}
                animate={{ scaleY: 1, opacity: 1 }}
                // exit={{ scaleY: 0.5, opacity: 0 }}
                transition={{
                  // type: 'spring',
                  // stiffness: 900,
                  // damping: 40,
                  duration: 1.5,
                }}
                style={{
                  textAlign: 'left',
                  width: 'fit-content', //'80vw',
                  margin: '4px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  zIndex: 90,
                  fontSize: '1rem',
                  background: '#00000099',
                  color: '#FFFFFF',
                  border: 'none',
                }}
              >
                {el}
              </li>
            ))}
          </AnimatePresence>
        </motion.ul>
      </motion.div>
    </>
  );
}

export default VideoContainer;
