import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Carousel from 'react-elastic-carousel';
import classes from './PracticeOuterContainer.module.css';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DroppableSection from './DroppableSection';
import './PracticeList.css';

import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

import isEqual from 'lodash/isEqual';
import Button from '@mui/material/Button';
import StopwatchLog from './StopWatch/StopwatchLog';
import PracticeCardListItemDemo from './PracticeCard/PracticeCardListItemDemo';
import { videoActions } from '../store/video';
import { tourActions } from '../store/tour';
import { myArrow } from './VisualSelection';
import ForMobileTooltip from './ForMobileTooltip';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 700, itemsToShow: 2, itemsToScroll: 1 },
  { width: 1100, itemsToShow: 3 },
  { width: 1450, itemsToShow: 4, itemsToScroll: 1 },
  //   { width: 1350, itemsToShow: 5 },
];

// alert(breakPoints.at(1)?.width);
// alert(window.innerWidth);
// console.log(window.innerWidth);

function PracticeList({
  // currentDeck,
  onTreeVideoClickHandler,
  practiceSectionsDisplayed,
  setViewCarousel,
  viewCarousel,
}) {
  console.log('🍋‍🟩🍋‍🟩 practiclice list rerender');

  const portalElement = document.getElementById('timer-container');

  const dispatch = useDispatch();
  const childRefs = useRef([]);
  const ref = useRef();
  const outerContainerRef = useRef(null);
  const [showTutorialToolTip, setShowTutorialToolTip] = useState(false);

  const [practiceDictionary, allSavedSelections, isMobile] = useSelector(
    (state) => [
      // state.auth.user.practiceDictionary,
      state.video.practiceDictionary,
      state.video.allSavedSelections,
      state.settings.isMobile,
    ],
    isEqual
  );

  const [activeSections, setActiveSections] = useState(() => {
    const data = JSON.parse(localStorage.getItem('stopwatch-new'));
    return data ? Object.keys(data) : [];
  });
  const [sectionBeingTimed, setSectionBeingTimed] = useState(() => {
    const data = JSON.parse(localStorage.getItem('stopwatch-new'));
    const isPlaying = data
      ? Object.keys(data).filter((id) => data[id].isPaused === false)
      : null;

    return isPlaying?.at(0)
      ? { id: data[isPlaying[0]].id, label: data[isPlaying[0]].label }
      : null;
  });

  // const [inDragMode, setInDragMode] = useState(false);
  console.log({ practiceDictionary });
  console.log({ allSavedSelections });

  const [columns, setColumns] = useState(() => {
    return dictionaryToColumns(practiceDictionary, allSavedSelections);
  });

  useEffect(() => {
    setColumns(dictionaryToColumns(practiceDictionary, allSavedSelections));
  }, [practiceDictionary]);
  console.log({ columns });

  const onDragEnd = (result, columns, setColumns) => {
    console.log({ result });
    console.log({ columns });
    if (!result.destination) return;
    const { source, destination } = result;
    const columnSource = columns[Number(source.droppableId)];
    const columnDestination = columns[Number(destination.droppableId)];
    const copiedItemsSource = [...columnSource.items];
    const copiedItemsDestination = [...columnDestination.items];
    const [removed] = copiedItemsSource.splice(source.index, 1);
    if (source.droppableId === destination.droppableId) {
      console.log('in equal if');
      copiedItemsSource.splice(destination.index, 0, removed);
    } else {
      // copiedItemsDestination.splice(destination.index, 1);
      copiedItemsDestination.splice(destination.index, 0, removed);
    }
    const nextColumns = [...columns];
    nextColumns[Number(destination.droppableId)] = {
      ...columnDestination,
      items: copiedItemsDestination,
    };

    nextColumns[Number(source.droppableId)] = {
      ...columnSource,
      items: copiedItemsSource,
    };

    if (copiedItemsSource.length === 0) {
      nextColumns.splice(Number(source.droppableId), 1);
    }

    reduceFolders(nextColumns);
    console.log({ nextColumns });
    setColumns(nextColumns);
    // setInDragMode(false);
  };
  // const onChangeColumn = (source, destination, columns, setColumns) => {
  const onChangeColumn = (source, destination) => {
    const columnSource = columns[Number(source.droppableId)];
    const columnDestination = columns[Number(destination.droppableId)];
    const copiedItemsSource = [...columnSource.items];
    const copiedItemsDestination = [...columnDestination.items];
    const [removed] = copiedItemsSource.splice(source.index, 1);
    // if (source.droppableId === destination.droppableId) {
    //   console.log('in equal if');
    //   return
    //   // copiedItemsSource.splice(destination.index, 0, removed);
    // } else {
    // copiedItemsDestination.splice(destination.index, 1);
    copiedItemsDestination.push(removed);
    // copiedItemsDestination.splice(destination.index, 0, removed);
    // }
    const nextColumns = [...columns];
    nextColumns[Number(destination.droppableId)] = {
      ...columnDestination,
      items: copiedItemsDestination,
    };
    nextColumns[Number(source.droppableId)] = {
      ...columnSource,
      items: copiedItemsSource,
    };
    if (nextColumns[Number(source.droppableId)].items.length === 0) {
      nextColumns.splice(Number(source.droppableId), 1);
    }
    console.log('in move column');
    console.log({ nextColumns });
    reduceFolders(nextColumns);
    setColumns(nextColumns);
  };

  const onAddNewColumn = (source, name) => {
    const columnSource = columns[Number(source.droppableId)];
    const copiedItemsSource = [...columnSource.items];
    const [removed] = copiedItemsSource.splice(source.index, 1);
    const destination = { name, items: [removed] };
    const nextColumns = [...columns];
    nextColumns[Number(source.droppableId)] = {
      ...columnSource,
      items: copiedItemsSource,
    };
    if (
      nextColumns[Number(source.droppableId)].items.length === 0 &&
      nextColumns[Number(source.droppableId).name !== 'unSorted']
    ) {
      nextColumns.splice(Number(source.droppableId), 1);
    }
    nextColumns.splice(nextColumns.length - 1, 0, destination);
    reduceFolders(nextColumns);
    setColumns(nextColumns);
  };

  const [windowWidth, setWindowWidth] = useState(
    document.querySelector('.rec-slider-container').clientWidth
  );

  useEffect(() => {
    // Function to update the state when window is resized
    const handleResize = () => {
      // console.log(
      //   'clientWidth: ',
      //   document.querySelector('.rec-slider-container').clientWidth
      // );
      setWindowWidth(
        document.querySelector('.rec-slider-container').clientWidth
      );
    };

    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run effect once on mount

  const columnsMapped = columns.map((column, i) => (
    <>
      <DroppableSection
        // ref={addChildRef}
        key={`${i}droppable`}
        items={column.items}
        goal={column.goal}
        columnKey={column.name}
        startDate={column.startDate}
        i={i}
        onTreeVideoClickHandler={onTreeVideoClickHandler}
        practiceSectionsDisplayed={practiceSectionsDisplayed}
        columns={columns}
        setColumns={setColumns}
        onChangeColumn={onChangeColumn}
        onAddNewColumn={onAddNewColumn}
        sectionBeingTimed={sectionBeingTimed}
        setSectionBeingTimed={setSectionBeingTimed}
        activeSections={activeSections}
        setActiveSections={setActiveSections}
      />
    </>
  ));

  useEffect(() => {
    if (sectionBeingTimed) {
      portalElement.classList.add('extend-stopwatch-element');
    } else {
      portalElement.classList.remove('extend-stopwatch-element');
    }
  }, [sectionBeingTimed]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
        height: '500px',
        width: '100%',
      }}
    >
      {/* <AnimatePresence> */}
      {sectionBeingTimed &&
        ReactDOM.createPortal(
          <motion.div
            // initial={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            // transition={{ duration: 1 }}
            style={{
              height: '40px',
              width: '100%',
              position: 'absolute',
              bottom: 0,
              zIndex: 1000,
            }}
          >
            {
              <StopwatchLog
                section={sectionBeingTimed.label}
                timePushed={9000000}
                pausedPushed={false}
                removeLog={() => {
                  setSectionBeingTimed(null);
                  setActiveSections([]);
                }}
              />
            }
          </motion.div>,
          portalElement
        )}
      {/* </AnimatePresence> */}

      <div
        ref={outerContainerRef}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
          height: '500px',
          width: '100vw',
        }}
      >
        <div
          className={classes['practice-summary-container']}
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 10,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            pointerEvents: 'none',
          }}
          onClick={() => {
            setViewCarousel((prev) => !prev);
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignContent: 'center',
              backgroundColor: 'var(--background-light-gray)',
              padding: '10px',
              pointerEvents: 'auto',
            }}
          >
            <ViewCarouselIcon
              style={{
                fontSize: '30px',
              }}
            />
          </div> */}
          <div
            style={{
              background:
                'linear-gradient(to right, var(--background-light-gray), transparent)',
              height: '100%',
              width: '20px',
              top: 0,
            }}
          ></div>
        </div>
        {columns.length > 0 ? (
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            onDragStart={() => {
              console.log('starting drag');
            }}
          >
            {/* <div
              style={{
                // width: `${Math.min(
                //   document.body.clientWidth,
                //   columnsMapped.length * 550
                // )}px`,
                width: '100%',
              }}
            > */}
            {/* {viewCarousel ? ( */}
            {true ? (
              <Carousel
                // renderArrow={customArrow}
                breakPoints={breakPoints}
                enableSwipe={isMobile}
                //   showArrows={!isMobile && title.length === 0}
                showArrows={!isMobile}
                // outerSpacing={1}
                // showEmptySlots
                //   enableSwipe={false}
                renderArrow={({ type, onClick, isEdge }) =>
                  myArrow(
                    type,
                    onClick,
                    isEdge,
                    'pagination-arrow-practice-list'
                  )
                }
                outerSpacing={0}
                showEmptySlots
                // isRTL={true}
                // itemPadding={[-90, 0]}
                //   style={{ gap: 0 }}
                renderPagination={
                  windowWidth <= breakPoints.at(1)?.width
                    ? ({ pages, activePage, onClick }) => {
                        return renderPagination(
                          pages,
                          activePage,
                          onClick,
                          columns
                          // isMobile
                        );
                      }
                    : undefined
                }
              >
                {columnsMapped}
              </Carousel>
            ) : (
              columnsMapped
            )}
            {/* </div> */}
          </DragDropContext>
        ) : (
          <div className="add-first-section-container">
            {/* <div>No practice sections yet! 😢</div> */}
            <div className="practice-thesis">
              Create saved sections to help organize and practice desired moves
            </div>
            <div
              style={{
                // border: '4px dashed var(--demo-color)',
                borderRadius: '10px',
                position: 'relative',
                height: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '300px',
              }}
            >
              <div className="demo-container">
                {/* <div>
                  <PracticeCardListItemDemo />
                  <PracticeCardListItemDemo />
                </div> */}
                <div>
                  <PracticeCardListItemDemo />
                  {/* <PracticeCardListItemDemo />
                  <PracticeCardListItemDemo /> */}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: '60px',
                    left: '60px',
                    backgroundColor: 'var(--background-light-gray)',
                    borderRadius: '10px',
                    zIndex: 55,
                  }}
                >
                  <PracticeCardListItemDemo />
                  {/* <PracticeCardListItemDemo /> */}
                </div>
              </div>
              {/* <div>
                <PracticeCardListItemDemo />
              </div> */}
            </div>

            <ForMobileTooltip isMobile={isMobile}>
              <Button
                onClick={() => {
                  dispatch(videoActions.showFirstAvailableVideo());
                  dispatch(tourActions.startTour('creatingSection'));

                  // handleClose();
                }}
                variant="contained"
                disabled={!isMobile}
              >
                View Tutorial
              </Button>
            </ForMobileTooltip>
          </div>
        )}
      </div>
    </div>
  );
}

export async function reduceFolders(columns) {
  const reducedColumns = columns
    .filter((col) => col.name != 'unSorted')
    .map((column) => {
      return { ...column, items: column.items.map((el) => el?.id) };
    });

  console.log('fuck it up: ', reducedColumns);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/users/updateSelectionFolder`,

      {
        method: 'PATCH',
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          practiceDictionary: reducedColumns,
        }),
      }
    );
    const data = await response.json();
    console.log({ data });
  } catch (err) {
    alert(err);
  }
}

function renderPagination(pages, activePage, onClick, columns) {
  console.log({ pages });
  console.log({ activePage });
  if (pages.length === 1) return <div></div>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',

        overflowX: 'scroll',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        // paddingTop: '5px',
        //   padding: 0,
        zIndex: 550,
        // background:
        //   'linear-gradient(to bottom, transparent 0%, var(--background-light-gray) 30%, var(--background-light-gray) 100%',
        backgroundColor: 'var(--background-light-gray)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          margin: '0 auto 0',
          padding: '0 10px',
          // gap: '16px',
        }}
      >
        {pages.map((page, index) => {
          const isActivePage = activePage === page;
          //   console.log({ pages });
          const pageName = columns[page]?.name;
          return (
            <>
              <motion.div
                // className={`${isActivePage ? 'active-render-tab' : ''}`}
                // layoutId={`${pageName}${index}`}
                // layoutId={`fuckmegood${isActivePage ? 'yes' : 'no'}`}
                key={page}
                onClick={() => onClick(page)}
                active={isActivePage}
                style={{
                  margin: '10px 3px',
                  minWidth: '15px',
                  borderRadius: 0,
                  padding: '4px 8px',
                  cursor: 'pointer',
                  color: isActivePage
                    ? 'var(--main-purple-no-transparancy)'
                    : 'var(--text-light-gray)',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    // width: '100%',
                    // minHeight: '100%',
                    // backgroundColor: 'yellow',
                    position: 'relative',
                    top: 0,
                    left: 0,
                    zIndex: 4,
                  }}
                >
                  {pageName?.length > 7
                    ? `${pageName.slice(0, 7)}..`
                    : pageName}
                </div>
                {/* <div style={{ zIndex: 0 }}> */}
                {isActivePage && (
                  <motion.div
                    className={'active-render-tab'}
                    layoutId="tabBacks"
                    style={{
                      width: '100%',
                      minHeight: '100%',
                      // backgroundColor: 'yellow',
                      // opacity: 0.8,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 0,
                    }}
                  ></motion.div>
                )}
                {/* </div> */}
              </motion.div>
            </>
          );
        })}
      </div>
    </div>
  );
}

function dictionaryToColumns(practiceDictionary, allSavedSelections) {
  console.log('using dictionaryToColumns function');
  const createColumns = practiceDictionary.map((obj) => {
    // console.log({ obj });
    // console.log({ practiceDictionary });
    // console.log({ allSavedSelections });
    return {
      ...obj,
      items: obj.items.map(
        (id) => allSavedSelections.filter((section) => section.id === id)[0]
      ),
    };
  });
  console.log({ createColumns });
  const sortedSections = practiceDictionary.reduce((accum, cur) => {
    accum.push(...cur.items);
    return accum;
  }, []);
  console.log({ sortedSections });
  const unSortedArray = allSavedSelections.filter(
    (el) => !sortedSections.includes(el?.id)
  );
  console.log({ unSortedArray });
  if (unSortedArray.length > 0) {
    createColumns.push({
      name: 'unSorted',
      items: unSortedArray,
    });
  }
  return createColumns;
}

export default React.memo(PracticeList);
