import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import './GlobalCSSVariables.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { videoActions } from './store/video';
import { friendActions } from './store/friend';
import useFetch from './hooks/useFetch';
import PracticeOuterContainer from './components/PracticeOuterContainer';
import VideoPage from './VideoPage';
import isEqual from 'lodash/isEqual';
import SearchPage from './SearchPage';
import { motion } from 'framer-motion';
let count = 0;

function MainPage() {
  const [
    isMobile,
    user,
    allVideos,
    recentVideos,
    sharedWithMeVideos,
    myUploads,
    savedSelections,
    activeVideoPage,
    favoriteVideos,
  ] = useSelector(
    (state) => [
      state.settings.isMobile,
      state.auth.user,
      state.video.allVideos,
      state.video.recentVideos,
      state.video.sharedWithMeVideos,
      state.video.myUploads,
      state.video.savedSelections,
      state.video.activeVideoPage,
      state.video.favoriteVideos,
    ],
    isEqual
  );

  console.log('🐶 mainpage rerender: ', count++);
  console.log('🐸 savedSelections: ', savedSelections);

  console.log('allVideos from store');
  console.log(allVideos);

  const dispatch = useDispatch();

  const addSearchObject = (obj) => {
    dispatch(videoActions.updateAutoCompleteValue(obj));
  };

  const { loading: allVidsLoading, error: allVidsError } = useFetch(
    `${process.env.REACT_APP_BACKEND}/api/v1/videos/fetchAllVids`,
    (allVidsValue) => {
      dispatch(videoActions.initialize(allVidsValue.data));
      dispatch(friendActions.initialize(allVidsValue.data.friends));
      console.log('calling useFetch for allVids');
    },
    { credentials: 'include' },
    [user?._id]
  );

  const onTreeVideoClickHandler = (id, sections, looptime) => {
    const video = [...sharedWithMeVideos, ...myUploads, ...allVideos].filter(
      (el) => el._id === id
    )[0];
    console.log('🦺 video filter, ', video);
    console.log('🦺 sections: ', sections);
    dispatch(
      videoActions.updateCurrentVideo({
        video,
        sections,
        looptime,
        // practiceIndex,
      })
    );
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          width: '300%',
          //  transitionProperty
        }}
      >
        {user &&
          [
            <>
              {!allVidsLoading && (
                <PracticeOuterContainer
                  key="Practice"
                  title="PracDEP"
                  isMobile={isMobile}
                  type="practice"
                  savedSelections={savedSelections}
                  onTreeVideoClickHandler={onTreeVideoClickHandler}
                />
                // <PracticeOuterContainer
                //   key="Practice"
                //   title="PracDEP"
                //   isMobile={isMobile}
                //   type="practice"
                //   savedSelections={savedSelections}
                //   onTreeVideoClickHandler={onTreeVideoClickHandler}
                // />
              )}
            </>,
            <>
              {activeVideoPage && (
                <VideoPage
                  user={user}
                  myUploads={myUploads}
                  sharedWithMeVideos={sharedWithMeVideos}
                  recentVideos={recentVideos}
                  favoriteVideos={favoriteVideos}
                  allVideos={allVideos}
                  isMobile={isMobile}
                  onTreeVideoClickHandler={onTreeVideoClickHandler}
                  addSearchObject={addSearchObject}
                />
              )}
            </>,
            <SearchPage onTreeVideoClickHandler={onTreeVideoClickHandler} />,
          ].map((el) => (
            <div className="holder-of-nav-and-video-container">
              <div className="holder-of-nav-and-video">
                <div className="hold-visual-rows">{el}</div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default MainPage;
