import React, { useEffect, useState, useRef } from 'react';
import ControlButton from './ControlButton';
import SlowmoButton from './SlowmoButton';
import PracticeForm from '../PracticeForm/PracticeForm';
import StopWatch from '../StopWatch/StopWatch';
import { videoActions } from '../../store/video';
import { stopwatchOutline, textOutline } from 'ionicons/icons';
import swipeDetect from '../swipeDetect';
import SectionBarList from '../SectionBarList';
import ShareIcon from '@mui/icons-material/Share';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import {
  makeStyles,
  Slider,
  withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from '@material-ui/core';
import {
  FastForward,
  FastRewind,
  Pause,
  PlayArrow,
  SkipNext,
  VolumeUp,
  VolumeOff,
} from '@material-ui/icons';
import './Control.css';
import NewFriendForm from '../../NewFriendForm';
import Notes from '../../store/Notes';
import StopWatchApp from '../StopWatch/StopWatchApp';
import { useDispatch, useSelector } from 'react-redux';
import MoreBarOptions from './MoreBarOptions';
import { Block } from '@mui/icons-material';
import determineClickCountFactory from '../determineClickCount';
import useScrollButtons from '../useScrollButtons';
import SlowmoSelect from './SlowmoSelect';
import RenameBar from './RenameBar';
import { tourActions } from '../../store/tour';
import FlipIcon from '@mui/icons-material/Flip';
import { AnimatePresence } from 'framer-motion';

let count = 0;
let elementHeight;

const doubleClickHandler = determineClickCountFactory();

const useStyles = makeStyles({
  volumeSlider: {
    width: '100px',
    color: '#9556CC',
  },

  bottomIcons: {
    color: '#999',
    padding: '12px 8px',

    '&:hover': {
      color: '#fff',
    },
  },
});

const PrettoSlider = withStyles({
  root: {
    height: '20px',
    color: 'white', //'#9556CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0 5px',
    marginBottom: '30px',
  },
  thumb: {
    height: '20px',
    width: '20px',
    backgroundColor: 'transparent', //#9556CC',
    border: 'none',
    // border: '2px solid currentColor',
    marginTop: -3,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
    width: '100%',
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

const PrettoSliderChopped = withStyles({
  root: {
    height: '30px',
    color: 'white', //'#9556CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 0 0px',
    zIndex: 100,
    top: 'calc(0.5 * (30px + 5px))',
    // marginBottom: '30px',
  },
  thumb: {
    height: '30px',
    width: '20px',
    backgroundColor: 'transparent', //#9556CC',
    border: 'none',
    // border: '2px solid currentColor',
    marginTop: -3,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
    width: '100%',
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

let topFadInterval;

const Control = ({
  onPlayPause,
  playing,
  onRewind,
  onForward,
  played,
  onSeek,
  onSeekMouseUp,
  onSeekMouseUpMoreOptions,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  onMute,
  duration,
  durationFormatted,
  currentTime,
  onMouseSeekDown,
  onMousSeekDownHandlerMoreOptions,
  controlRef,

  allSectsWNotes,
  videoData,

  playbackRate,
  handleSetPlayback,
  videoPlayerRef,
  seekToTime,
  sectionClickHandler,
  selectedSections,
  addSectionHandler,
  // setSearchArray,
  currentBar,
  variableSpeedComponent,
  isVariableSlowMo,
  handleClearCoords,

  fullscreen,
  setFullscreen,

  flipHorizontal,
  setFlipHorizontal,
  onHomeClick,
  isIOS,
  selectionIndices,
  onTreeVideoClickHandler,
}) => {
  // console.log('PROPSSS');
  // console.log(props);
  // console.log('selectionIndices fuck Control');
  // console.log({ selectionIndices });

  const dispatch = useDispatch();

  const classes = useStyles();
  const [barToRename, setBarToRename] = useState(null);
  const [showNoteModal, setShowNotesModal] = useState(false);
  const [addingNewFriend, setAddingNewFriend] = useState(false);
  const [showStopWatch, setShowStopWatch] = useState(false);
  const [showSlowmoSelect, setShowSlowmoSelect] = useState(false);
  const [showBaseControls, setShowBaseControls] = useState(true);
  const [showSectionBarList, setShowSectionBarList] = useState(true);
  const sectionListRef = useRef(null);
  // const sectionListForOpacityRef = useRef(null);
  const [temporarilyHideControls, setTemporarilyHideControls] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [showBarOptions, looptime, notesArray] = useSelector((state) => [
    state.video.showBarOptions,
    state.video.looptime,
    state.video.notesArray,
  ]);
  const [showControlsFromClick, setShowControlsFromClick] = useState(false);
  const [showPracticeForm, setShowPracticeForm] = useState(false);
  // const [scrollFade, setScrollFade] = useState({ top: false, bottom: false });
  const divSizes = useRef({});
  // constfff
  // const [ ]

  useEffect(() => {
    if (selectedSections.length > 0) {
      dispatch(videoActions.setShowBarOptions(true));
    }
  }, []);

  useEffect(() => {
    const parentDiv = document.getElementById('captions-container');

    if (parentDiv) {
      // if (selectedSections.length > 0) {
      //   while (element.firstChild) {
      //     element.removeChild(element.firstChild);

      // }
      if (parentDiv.hasChildNodes() && showCC) {
        {
          setTimeout(function () {
            if (parentDiv.hasChildNodes()) {
              parentDiv.removeChild(parentDiv.lastChild);
            }
          }, 2000);
        }
      }
      if (showCC) {
        createAndAddElement(parentDiv, currentBar, notesArray?.at(currentBar));
      }
    }
  }, [currentBar]);

  useEffect(() => {
    const parentDiv = document.getElementById('captions-container');

    if (parentDiv) {
      if (!showCC) {
        while (parentDiv.firstChild) {
          parentDiv.removeChild(parentDiv.firstChild);
        }
      }
    }
  }, [showCC]);

  // useEffect(() => {
  //   if (!showSectionBarList || temporarilyHideControls) return;
  //   swipeDetect(
  //     sectionListRef.current,
  //     () => {
  //       setShowSectionBarList(false);
  //     },
  //     sectionListForOpacityRef.current
  //   );
  // }, [showSectionBarList, temporarilyHideControls]);

  // use effect to properly place scroll at beginning of mound

  useEffect(() => {
    if (videoData.sections.length === 0) return;

    const computedStyle = getComputedStyle(sectionListRef.current);

    elementHeight = sectionListRef.current.clientHeight; // height with padding
    elementHeight -=
      parseFloat(computedStyle.paddingTop) +
      parseFloat(computedStyle.paddingBottom);

    console.log('alert, height of element: ', elementHeight);
  }, []);

  // const topBu

  useEffect(() => {
    if (selectedSections.length === 0) return;
    let currentSectionIndex = selectedSections[0];

    const sectionHeight = 40;
    const buffer = 140;
    // const scrollHeight = 500;
    if (sectionListRef?.current) {
      sectionListRef.current.scrollTop = Math.max(
        0,
        sectionHeight * currentSectionIndex - 50
      );
    }
  }, [temporarilyHideControls, showSectionBarList]);

  useEffect(() => {
    if (selectedSections.length === 0) return;
    let currentSectionIndex = selectedSections[0];

    const sectionHeight = 40;
    const buffer = 140;
    // const scrollHeight = 500;
    sectionListRef.current.scrollTop = Math.max(
      0,
      sectionHeight * currentSectionIndex - 50
    );
  }, []);

  const controlClickHandler = () => {
    doubleClickHandler(
      () => {
        // seekToTime(time);
        if (playing) {
          // console.log('click while ');
          setShowControlsFromClick((prev) => !prev);
          // setShowControlsFromClick(true);
          // setTimeout(() => {
          //   setShowControlsFromClick(false);
          // }, 3000);
        }
        console.log('single click');
      },
      () => {
        // dispatch(videoActions.updateSelectedSections(originalIndex));
        onPlayPause();
        // removeItem(id);
      }
    );
  };

  console.log('🪢 control rerender: ', count++);

  return (
    <>
      {showNoteModal && (
        <Notes
          allSectsWNotes={allSectsWNotes}
          onModalClose={() => {
            setShowNotesModal(false);
          }}
        />
      )}
      {addingNewFriend && (
        <NewFriendForm
          // sectionWNotes={allSectsWNotes}
          videoId={videoData._id}
          onModalClose={() => {
            setAddingNewFriend(false);
          }}
        />
      )}
      {showPracticeForm && (
        <PracticeForm
          onModalClose={() => {
            setShowPracticeForm(false);
          }}
          // selectedSections={selectedSections}
          // sections={videoData?.sections}
          defaultTitle={videoData?.folderLocation?.join(' ')}
        />
      )}
      {!!barToRename && barToRename?.index >= 0 && (
        <RenameBar
          index={barToRename.index}
          defaultLabel={barToRename.label}
          onModalClose={() => {
            setBarToRename(null);
          }}
        />
      )}
      <div className="control_Container" ref={controlRef}>
        <div className="top-absolute-container">
          {showStopWatch && (
            <div className="stopwatch-container">
              <StopWatch />
            </div>
          )}
        </div>
        <div style={{ position: 'absolute', height: '100%' }}> </div>

        <div className="top_container" style={{ flexBasis: '55px' }}>
          <h2></h2>
        </div>
        <div
          className="mid__container"
          onClick={controlClickHandler}
          style={{ flexGrow: 1 }}
        >
          {!temporarilyHideControls && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0 ',
              }}
            >
              <div className="slide-in-from-left" id="back-button-controls">
                <ControlButton
                  onClickHandler={onHomeClick}
                  // source="https://icons.veryicon.com/png/o/miscellaneous/ui-designer-icon/arrow-207.png"
                  // imageScale={0.8}
                  muiIcon={
                    <ArrowBackOutlinedIcon style={{ fontSize: '35px' }} />
                  }
                  background="none"
                  // color="purple"
                />
              </div>
              {(!playing || showControlsFromClick) && (
                <div className="slide-in-from-left left-contols-grouping">
                  {/* <ControlButton
                    onClickHandler={() => {
                      setShowStopWatch((prev) => !prev);
                    }}
                    // icon={stopwatchOutline}
                    // source={`${cloudfrontPath}/stopwatch-simple.svg`}
                    // iconFontSize={40}
                    muiIcon={<TimerOutlinedIcon style={{ fontSize: '35px' }} />}
                    // highlight={true}
                    background="none"
                  /> */}
                  <ControlButton
                    onClickHandler={() => {
                      setShowNotesModal((prev) => !prev);
                    }}
                    // source="https://img.icons8.com/pulsar-line/96/note.png"
                    // imageScale={0.75}
                    muiIcon={
                      <TextSnippetOutlinedIcon style={{ fontSize: '35px' }} />
                    }
                    // highlight={true}
                    background="none"
                    // disabled={showSectionBarList}
                  />

                  {/* <ControlButton
                    onClickHandler={() => {
                      setShowCC((prev) => !prev);
                    }}
                    // icon={textOutline}
                    muiIcon={
                      <ClosedCaptionOffOutlinedIcon
                        style={{ fontSize: '35px' }}
                      />
                    }
                    highlight={showCC}
                    // iconFontSize={40}
                    // color={'green'}
                    // imageScale={0.7}
                    background="none"
                    // disabled={showSectionBarList}
                  /> */}

                  {/* <SpeedOutlinedIcon color="white" /> */}

                  <SlowmoButton
                    playbackRate={playbackRate}
                    onSetPlayback={handleSetPlayback}
                    setShowSlowmoSelect={setShowSlowmoSelect}
                    isVariableSlowMo={isVariableSlowMo}
                  />

                  <ControlButton
                    onClickHandler={() => {
                      setFlipHorizontal((prev) => !prev);
                    }}
                    // source="https://img.icons8.com/pulsar-line/96/note.png"
                    // imageScale={0.75}
                    muiIcon={<FlipIcon style={{ fontSize: '35px' }} />}
                    highlight={flipHorizontal}
                    background="none"
                    // disabled={showSectionBarList}
                  />

                  {/* <ControlButton
                    onClickHandler={() => {
                      setAddingNewFriend(true);
                    }}
                    // source={`${cloudfrontPath}/share-outline.svg`}
                    // imageScale={0.7}
                    background="none"
                    muiIcon={<ShareOutlinedIcon style={{ fontSize: '35px' }} />}
                    // highlight={true}
                    // disabled={showSectionBarList}
                  /> */}
                </div>
              )}
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '5px',
            }}
          >
            {!showSectionBarList && (
              <>
                <ControlButton
                  onClickHandler={() => {
                    setShowSectionBarList((prev) => !prev);
                    dispatch(tourActions.handleLinkedAction('showSectionBar'));
                  }}
                  className="sectionBarList-show-button"
                  // source="https://icons.veryicon.com/png/o/miscellaneous/offerino-icons/list-bullet.png"
                  // imageScale={0.7}

                  muiIcon={<MenuIcon style={{ fontSize: '35px' }} />}
                  background="none"

                  // disabled={showSectionBarList}
                />
              </>
            )}
          </div>
          {/* <div className="icon__btn" onDoubleClick={onRewind}>
          <FastRewind fontSize="medium" />
        </div>

        <div className="icon__btn" onClick={onPlayPause}>
          {playing ? (
            <Pause fontSize="medium" />
          ) : (
            <PlayArrow fontSize="medium" />
          )}{' '}
        </div>

        <div className="icon__btn">
          <FastForward fontSize="medium" onDoubleClick={onForward} />
        </div> */}
          {/* <div id="captions-container">{notesArray[currentBar]}</div> */}
          <div
            id="captions-container"
            className="captions"
            style={{ zIndex: 600 }}
          >
            {/* boddabind */}
          </div>
        </div>
        {(!playing || showControlsFromClick) && (
          <>
            <div className="bottom__container slide-in-from-bottom">
              <div className="slider__container">
                <div className="controls-bottom">
                  <div
                    onClick={() => {
                      onPlayPause();
                    }}
                  >
                    {playing ? (
                      <PauseIcon fontSize="large" />
                    ) : (
                      <PlayArrowIcon fontSize="large" />
                    )}
                  </div>
                  <div style={{ flexGrow: 1 }} />

                  {!isIOS && (
                    <div
                      onClick={() => {
                        setFullscreen((prev) => !prev);
                      }}
                    >
                      {fullscreen ? (
                        <FullscreenExitOutlinedIcon fontSize="large" />
                      ) : (
                        <FullscreenIcon fontSize="large" />
                      )}
                    </div>
                  )}
                  <div
                    onClick={() => {
                      // console.log('helow');
                      setAddingNewFriend(true);
                    }}
                    style={{
                      height: '35px',
                      width: '35px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ShareIcon
                      fontSize="medium"
                      onClickHandler={() => {
                        setAddingNewFriend(true);
                      }}
                    />
                  </div>
                  {/* <div>
                    <VolumeDownIcon fontSize="large" />
                  </div>
                  <div>
                    <SettingsIcon fontSize="large" />
                  </div> */}
                </div>
                <div className="played-duration-container">
                  {/* asdfsdf */}
                  {/* <span>{`${currentTime} / ${duration}`}</span> */}
                  <div
                    className="individual-time"
                    style={{ fontWeight: 'bold', color: 'white' }}
                    //  onMouseDown={() => false}
                  >
                    {currentTime}
                  </div>
                  <div style={{ padding: '0 3px' }}>/</div>
                  <div className="individual-time">{durationFormatted}</div>
                </div>
                <PrettoSlider
                  // padding={50}
                  min={0}
                  max={100}
                  value={played * 100}
                  onChange={onSeek}
                  onChangeCommitted={onSeekMouseUp}
                  onMouseDown={onMouseSeekDown}
                />
              </div>
            </div>
          </>
        )}

        {/* <div className="control__box">
          <div className="inner__controls">
            <div className="icon__btn" onClick={onPlayPause}>
              {playing ? (
                <Pause fontSize="medium" />
              ) : (
                <PlayArrow fontSize="medium" />
              )}{' '}
            </div>

            <div className="icon__btn">
              <SkipNext fontSize="medium" />
            </div>

            <div className="icon__btn" onClick={onMute}>
              {mute ? (
                <VolumeOff fontSize="medium" />
              ) : (
                <VolumeUp fontSize="medium" />
              )}
            </div>

            <Slider
              className={`${classes.volumeSlider}`}
              onChange={onVolumeChangeHandler}
              value={volume * 100}
              onChangeCommitted={onVolumeSeekUp}
            />

            <span>
              {currentTime} : {duration}
            </span>
          </div>
        </div> */}

        {showSlowmoSelect && (
          <SlowmoSelect
            playbackRate={playbackRate}
            onSetPlayback={handleSetPlayback}
            variableSpeedComponent={variableSpeedComponent}
            isVariableSlowMo={isVariableSlowMo}
            handleClearCoords={handleClearCoords}
            setShowSlowmoSelect={setShowSlowmoSelect}
          />
        )}
        {/* <AnimatePresence> */}
        {showSectionBarList &&
          videoData.sections.length > 0 &&
          !temporarilyHideControls && (
            <SectionBarList
              // currentTime={videoPlayerRef.current?.getCurrentTime()}
              videoData={videoData}
              selectedSections={selectedSections}
              // setSelectedSections={() => {}}
              // addSectionHandler={addSectionHandler}
              // onClickForSectionBar={onClickForSectionBar}
              // setCurrSectionBar={setCurrSectionBar}
              // currSectionBar={currSectionBar}
              // sectionClickHandler={sectionClickHandler}
              // addSavedSelections={[]}
              seekToTime={seekToTime}
              sectionListRef={sectionListRef}
              // setShowOutOfBoundsButton={() => {}}
              // notes={notes}
              showSectionBarList={showSectionBarList}
              temporarilyHideControls={temporarilyHideControls}
              setShowSectionBarList={setShowSectionBarList}
              setBarToRename={setBarToRename}
              selectionIndices={selectionIndices}
              onTreeVideoClickHandler={onTreeVideoClickHandler}
            />
          )}
        {/* </AnimatePresence> */}
        {showBarOptions && (
          <MoreBarOptions
            onSeek={onSeek}
            played={played}
            onSeekMouseUp={onSeekMouseUp}
            onMouseDown={onMouseSeekDown}
            duration={duration}
            setTemporarilyHideControls={setTemporarilyHideControls}
            setShowPracticeForm={setShowPracticeForm}
            playSlider={
              <PrettoSliderChopped
                // padding={50}
                min={(looptime[0] / duration) * 100}
                max={(looptime[1] / duration) * 100}
                value={played * 100}
                onChange={onSeek}
                onChangeCommitted={onSeekMouseUp}
                onMouseDown={onMouseSeekDown}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export function createAndAddElement(
  parentDiv,
  barIndex,
  captionText,
  deleteSoon = false
) {
  if (captionText) {
    const childDiv = document.createElement('motion.div');
    // const childDiv = React.createElement('motion.div', {
    //   // key: captionText, // a unique value, specially for React
    //   className:
    //   myKey: 'icanbeeverything', // whatever you want
    // },'asdfsaddsf');
    childDiv.key = captionText;
    childDiv.layout = true;

    childDiv.initial = { opacity: 0, x: -400, scale: 0.5 };
    childDiv.animate = { opacity: 1, x: 0, scale: 1 };
    childDiv.exit = { opacity: 0, x: 200, scale: 1.2 };
    childDiv.transition = { duration: 0.6, type: 'spring' };
    childDiv.textContent =
      captionText.slice(0, 140) + (captionText.length > 140 ? '...' : '');
    childDiv.classList.add('single-caption');
    // parentDiv.appendChild(childDiv);
    parentDiv.insertBefore(childDiv, parentDiv.firstChild);
    // console.log({ captionText });
    // console.log(captionText.length);
    // console.log(
    //   'time is: ',
    //   captionText?.length ? Math.max(captionText.length * 50, 2500) : 3000
    // );

    if (deleteSoon) {
      setTimeout(
        function () {
          if (parentDiv.hasChildNodes()) {
            parentDiv.removeChild(parentDiv.lastChild);
          }
        },
        captionText?.length ? Math.max(captionText.length * 50, 2500) : 3000
      );
    }
  }
}

export default Control;
// export createAndAddElement;
