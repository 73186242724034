import React, { useState } from 'react';
import classes from './VisualSelection.module.css';
import Carousel from 'react-elastic-carousel';
import './Carousel.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { motion, AnimatePresence } from 'framer-motion';
import AllVideosFromSection from './AllVideosFromSection';

let count = 0;

const VisualSelection = ({
  title,
  isMobile,
  children,
  isExpandedInitial = false,
  // type = 'default',
  withFilters = false,
  dateSorted = true,
  videoSectionId,
  setCurrentSectionId,
  showAllVideoRef,
  borderBottom = true,
  showCount = true,
}) => {
  console.log('👄 Visual selection rerender: ', count++);

  const [isExpanded, setIsExpanded] = useState(isExpandedInitial);
  const [isChecked, setIsChecked] = useState(new Array(2).fill(true));

  // console.log({ children });
  let filledCategories, childrenFiltered, videoSorted;
  if (withFilters) {
    filledCategories = children.filter((el) => el.videos.length > 0);
    // if there is no video in children, return nothing
    if (filledCategories.length === 0) return;

    childrenFiltered = filledCategories.map((el, index) => {
      if (isChecked[index]) {
        return el.videos;
      } else {
        return [];
      }
    });

    // console.log({ filledCategories });

    videoSorted = childrenFiltered.reduce((acum, cur) => {
      console.log({ cur });
      return acum.concat(...cur);
    }, []);
    // console.log({ childrenFiltered });
    // console.log({ videoSorted });
    videoSorted.sort((a, b) => {
      return (
        new Date(b.props.videoData.dateRecorded) -
        new Date(a.props.videoData.dateRecorded)
      );
    });
  } else if (dateSorted) {
    videoSorted = children;
    // console.log({ childrenFiltered });
    // console.log({ videoSorted });
    videoSorted.sort((a, b) => {
      return (
        new Date(b.props.videoData.dateRecorded) -
        new Date(a.props.videoData.dateRecorded)
      );
    });
  }

  // childrenFiltered.sort(vid)

  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    { width: 300, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 650, itemsToShow: 3, itemsToScroll: 3 },
    { width: 920, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1350, itemsToShow: 5, itemsToScroll: 5 },
  ];

  const processedVideos = !withFilters && !dateSorted ? children : videoSorted;

  const titleFinal = !withFilters
    ? title
    : filledCategories.length === 1
    ? filledCategories[0].label
    : 'All Videos';

  return (
    <div
      className={
        classes['practice-contain-all'] +
        ` ${title === 'Favorites' ? classes['favorite'] : ''}`
      }
      style={{ borderBottom: borderBottom ? '' : 'none' }}
    >
      <AnimatePresence>
        {isExpanded && (
          <AllVideosFromSection
            title={titleFinal}
            onModalClose={() => {
              setIsExpanded(false);
            }}
            showAllVideoRef={showAllVideoRef}
          >
            {processedVideos}
          </AllVideosFromSection>
        )}
      </AnimatePresence>
      <div style={{ width: 'auto', margin: '10px 10px' }}>
        <div className={classes['title-block']}>
          <div
            className={
              classes['title'] +
              ` ${title === 'Favorites' ? classes['favorite'] : ''}`
            }
            style={{ display: 'inline' }}
            // onClick={() => {
            //   setIsExpanded((prev) => !prev);
            // }}
            // will need to change children.length when i introduce pagination
          >
            {/* <motion.div layoutId={`expanded-title-${titleFinal}`}> */}
            <span>{titleFinal}</span>
            {showCount && (
              <span>{` (${
                withFilters ? videoSorted.length : children.length
              }) `}</span>
            )}
          </div>
          <div
            className={classes['see-all']}
            onClick={() => {
              if (isExpanded) {
                setIsExpanded(false);
                // setCurrentSectionId(undefined);
              } else {
                setIsExpanded(true);
                // setCurrentSectionId(videoSectionId);
              }
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            {/* {isExpanded ? 'minimize' : 'see all'} */}
            {/* <WestOutlinedIcon style={{ fontSize: '20px' }} /> */}
            <div>see all</div>
          </div>
        </div>
        {withFilters && filledCategories.length > 1 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              color: 'var(--accent)',
              marginLeft: '10px',
              // color: 'var(--text-light-gray)',
            }}
          >
            {/* <select defaultValue="newest">
              <option value="newest">Newest first</option>
              <option value="oldest">Oldest first</option>
            </select> */}

            {filledCategories.map((child, index) => (
              <>
                <label>
                  <input
                    type="checkbox"
                    // disabled={true}
                    checked={isChecked[index]}
                    onChange={(e) =>
                      setIsChecked((prev) => {
                        const next = [...prev];
                        next[index] = !prev[index];
                        return next;
                      })
                    }
                    style={{ marginRight: '5px' }}
                  />
                  {child.label}
                </label>
              </>
            ))}
          </div>
        )}
      </div>
      <div className={classes['contain-the-scrollable']}>
        <div className={classes['video-box-container']}>
          {/* {isExpanded ? (
            <SeeAllVideos
              title={titleFinal}
              onModalClose={() => {
                setIsExpanded(false);
              }}
            >
              {processedVideos}
            </SeeAllVideos>
          ) : ( */}
          <Carousel
            // style={{ margin: 0 }}
            renderPagination={() => {
              return <div>{/* Empty, or custom pagination */}</div>;
            }}
            renderArrow={({ type, onClick, isEdge }) =>
              myArrow(type, onClick, isEdge)
            }
            // preventDefaultTouchmoveEvent={true}
            breakPoints={breakPoints}
            showArrows={!isMobile}
            disableArrowsOnEnd
            showEmptySlots
            outerSpacing={15}
            enableSwipe
            enableMouseSwipe
            // isRTL={true}
            // itemPadding={[0, 0]}
          >
            {processedVideos}
          </Carousel>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default VisualSelection;

export function myArrow(type, onClick, isEdge, extraClass = '') {
  // console.log({ type });
  // console.log({ isEdge });
  const pointer =
    type === 'PREV' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
  // if (isEdge) return <div></div>;

  return (
    <div className="arrow-container">
      <div
        className={`pagination-arrow ${
          type === 'PREV' ? 'left-arrow' : 'right-arrow'
        } ${extraClass}`}
        onClick={onClick}
        // disabled={isEdge}
        style={{ opacity: isEdge ? 0 : 1 }}
      >
        <div
          style={{
            position: 'relative',
            right: type === 'PREV' ? '-3px' : 0,
          }}
        >
          {' '}
          {pointer}
        </div>
      </div>
    </div>
  );
}
