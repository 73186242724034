import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

let elementHeight;
const sectionHeight = 40;
export default function (containerRef) {
  const [currentBar, currentVideo, selectedSections] = useSelector((state) => [
    state.video.currentBar,
    state.video.currentVideo,
    state.video.selectedSections,
  ]);
  const [outOfBoundsLoc, setOutOfBoundsLoc] = useState('');
  const [triggerUpdate, setTriggerUpdate] = useState(0);

  const resolveOutOfBounds = (e) => {
    e.stopPropagation();
    if (selectedSections?.length > 0) {
      containerRef.current.scrollTo({
        top: Math.max(0, sectionHeight * selectedSections[0] - 10),
        behavior: 'smooth',
      });
    } else {
      containerRef.current.scrollTo({
        top: Math.max(0, sectionHeight * currentBar - 10),
        behavior: 'smooth',
      });
    }
    // containerRef.current.scrollTop = Math.max(
    //   0,
    //   sectionHeight * currentBar - 10
    // );
  };

  const scrollFunc = () => {
    const scrollTop = containerRef?.current?.scrollTop || 0;
    //   console.log({ outOfBoundsLoc });
    // check to see if middle of current bar is within view
    // area of viewing is bounded by
    const lowerBound = scrollTop - 5;
    const upperBound = scrollTop + elementHeight + 5;
    const barMidLocation = currentBar * sectionHeight + 0.5 * sectionHeight;

    if (barMidLocation < lowerBound) {
      //   console.log('scroll: bar too low!');
      if (outOfBoundsLoc !== 'top') {
        setOutOfBoundsLoc('top');
      }
    } else if (barMidLocation > upperBound) {
      if (outOfBoundsLoc !== 'bottom') {
        setOutOfBoundsLoc('bottom');
      }
    } else {
      if (outOfBoundsLoc !== '') {
        setOutOfBoundsLoc('');
      }
      //   setOutOfBoundsLoc('');
    }
  };

  //   const scrollFunc = (() => {
  //     let count = 0;
  //     return () => {
  //       console.log('scroll triggering');
  //       count++;
  //       //   setTriggerUpdate(count++);
  //       console.log({ count });
  //     };
  //   })();

  useEffect(() => {
    const computedStyle = getComputedStyle(containerRef.current);

    elementHeight = containerRef.current.clientHeight; // height with padding
    elementHeight -=
      parseFloat(computedStyle.paddingTop) +
      parseFloat(computedStyle.paddingBottom);

    console.log('alert, height of element: ', elementHeight);
  }, [currentVideo]);

  useEffect(() => {
    // console.log('in scroll trigger use effect');

    containerRef.current.addEventListener('scroll', scrollFunc);
    return () => {
      //   console.log('scroll run CLEANUP in useefffec');
      containerRef?.current?.removeEventListener('scroll', scrollFunc);
    };
  }, [currentBar, outOfBoundsLoc]);

  useEffect(() => {
    scrollFunc();
  }, [currentBar]);

  //   containerRef.current?.scrollTop

  return [outOfBoundsLoc, resolveOutOfBounds];
}
