import React, { useCallback, useEffect, useRef, useState } from 'react';
import SectionBar from './SectionBar';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import './SectionBarList.css';
import determineClickCountFactory from './determineClickCount';
import { inputClasses } from '@mui/material';
import { grid } from 'ionicons/icons';
import { videoActions } from '../store/video';

import Bar from './Bar';
import MotionBar from './Bar';
import BundleWrapper from './video_components/BundleWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { tourActions } from '../store/tour';

let count = 0;
const doubleClickHandler = determineClickCountFactory();

export default function Bundle({
  bundle,
  index,
  hideTertiary,
  seekToTime,
  bundleLabel,
  backgroundColor,
  sectionListRef,
  setBarToRename,
  selectionIndices,
  onTreeVideoClickHandler,
}) {
  console.log('❄️ bundle rerender: ', count++);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [list, setList] = useState(
    hideTertiary && bundleLabel === 'tertiary' ? [] : bundle
  );
  const [notesArray, currentBar, selectedSections, tourActive] = useSelector(
    (state) => [
      state.video.notesArray,
      state.video.currentBar,
      state.video.selectedSections,
      state.tour.active,
    ]
  );
  // console.log('🧑🏾‍🍼 notes array in bundle.js: ', notesArray);
  //   const backgroundColor = bundleLabel ? '#3498ebAA' : '#44444488';

  useEffect(() => {
    setList(hideTertiary && bundleLabel === 'tertiary' ? [] : bundle);
  }, [bundle]);

  useEffect(() => {
    if (hideTertiary && bundle[0].label?.length === 0) {
      // setList([]);
    }
    console.log('🤖 seekto: ', seekToTime);
    // seekTo(10);
  }, [hideTertiary]);

  useEffect(() => {}, [list]);

  // const handleClick = (id, time, originalIndex) => {
  //   doubleClickHandler(
  //     () => {
  //       seekToTime(time);
  //       console.log('single click');
  //     },
  //     () => {
  //       dispatch(videoActions.updateSelectedSections(originalIndex));

  //       // removeItem(id);
  //     }
  //   );
  // };

  const handleClick = useCallback(
    (id, time, originalIndex) => {
      doubleClickHandler(
        () => {
          seekToTime(time);
          console.log('single click');
          dispatch(tourActions.handleLinkedAction('barSingleClick'));
        },
        () => {
          // dispatch(videoActions.doubleClickSelectedSections(originalIndex));
          dispatch(tourActions.handleLinkedAction('barDoubleClick'));
          dispatch(videoActions.updateSelectedSections(originalIndex));
        }
      );
    },
    [dispatch]
  );

  const testClickHandler = (time) => {
    seekToTime(time);
  };

  const removeItem = (id) => {
    // console.log('list: ', list);
    // const newList = list.filter((section) => section._id !== id);
    // setList(newLi
    console.log('items before finding index: ', items);
    // const indexToDelete = items.indexOf(id);
    setList([]);

    // setList(list.filter((section) => section._id !== id));
  };

  //   const count = useRef(0);
  const [items, setItems] = useState([0]);
  //   const [popLayout, setPopLayout] = useState(false);
  //   console.log('🕺🏻section details: ', sectionInfo);
  //   console.log('item arary: ', items);
  const barGrouping = list.map((section, index) => {
    const id = section._id;
    const start = section.fourCountTimes[0];
    const countLabel = section.countLabel;
    const label = section.label;
    const selectionExists = selectedSections.length > 0;
    const isSelected = selectedSections.includes(section.originalIndex);
    const isFirst = selectedSections[0] === section.originalIndex;
    const isLast =
      selectedSections[selectedSections.length - 1] === section.originalIndex;

    // console.log('looking for notes in bar grounping: ', section);
    // const handleClick = handleC

    const isInASelectionFirst = selectionIndices.first?.includes(
      section.originalIndex
    );
    let selectionIndex;
    if (isInASelectionFirst) {
      selectionIndex = selectionIndices?.first?.findIndex(
        (el) => el == section.originalIndex
      );
    }

    return (
      <MotionBar
        // ref={ref}
        key={id}
        id={id}
        start={start}
        countLabel={countLabel}
        label={label}
        handleClick={handleClick}
        originalIndex={section.originalIndex}
        // backgroundColor={backgroundColor}
        // notes={section.notes}
        notes={notesArray?.at(section.originalIndex)}
        sectionListRef={sectionListRef}
        // currentBar={currentBar}
        isCurrent={
          (!selectionExists && currentBar === section.originalIndex) ||
          (selectionExists &&
            selectedSections.includes(currentBar) &&
            currentBar === section.originalIndex) ||
          (selectedSections[0] === section.originalIndex &&
            currentBar < section.originalIndex) ||
          (selectedSections[selectedSections.length - 1] ===
            section.originalIndex &&
            currentBar > section.originalIndex)
        }
        // selectionExists={selectedSections.length > 0}
        isSelected={selectedSections.includes(section.originalIndex)}
        isFirst={isFirst}
        isLast={isLast}
        setBarToRename={setBarToRename}
        isInASelection={selectionIndices.all?.includes(section.originalIndex)}
        isInASelectionFirst={isInASelectionFirst}
        isInASelectionLast={selectionIndices.last?.includes(
          section.originalIndex
        )}
        onTreeVideoClickHandler={onTreeVideoClickHandler}
        savedSelection={
          selectionIndex > -1 && selectionIndices?.section?.at(selectionIndex)
        }
        // savedSelection={selectionIndices?.section?.at(1)}
      />
    );
  });

  return (
    <motion.div
      layout
      key={'bundle-container' + String(index)}
      className={`full-length-sectionlist example ${
        bundle[0].label.length === 0 ? 'tertiary' : 'primary'
      }`}
      style={{
        gridColumn: `span ${list.length === 0 ? 1 : 3}`,
        gap: '20px',
        zIndex: 2,
      }}
    >
      <motion.ul layout key={'motionUL' + String(index)}>
        {/* <AnimatePresence mode="popLayout"> */}
        {bundleLabel.length > 12 ? (
          <BundleWrapper>{barGrouping}</BundleWrapper>
        ) : (
          barGrouping
        )}
        {list.length === 0 && (
          <motion.li
            layout
            //   layoutId={`count-holder0`}
            key={'collapse' + String(index)}
            initial={{ scale: 2, opacity: 1, y: 0 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            //   transition={{ delay: 1 }}
            onClick={() => {
              setList(bundle);
            }}
            className="count-box"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '30px',
              backgroundColor: backgroundColor,
            }}
          >
            {bundle.length}
          </motion.li>
        )}
        {/* </AnimatePresence> */}
      </motion.ul>
    </motion.div>
  );
}
