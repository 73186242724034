import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  isAuthenticated: false,
  // isAuthenticated: true, //dev mode
  user: null,
  // favoriteVideos: []
};

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    updateNewVideos(state, action) {
      const nextUser = { ...state.user };
      nextUser.newVideos = nextUser.newVideos.filter(
        (el) => el != action.payload
      );
      state.user = nextUser;
    },

    updateAllFavorites(state, action) {
      const nextUser = { ...state.user };
      nextUser.favoriteVideos = action.payload;
      state.user = nextUser;
    },
    likeVideo(state, action) {
      const { videoId, favorited } = action.payload;

      const currFavorites = state.user.favoriteVideos;
      // const currVids = user.recentlyViewedVideos;
      // const newVideos = user.newVideos;
      let nextFavorites;

      if (favorited) {
        nextFavorites = [...currFavorites, videoId];
      } else {
        nextFavorites = currFavorites.filter((vid) => vid != videoId);
      }
      const nextUser = { ...state.user };
      nextUser.favoriteVideos = nextFavorites;
      state.user = nextUser;
    },
  },
});

// export function favoriteVideo(videoId, liked) {
//   console.log('inthinkkk!');
//   return async (dispatch, getState) => {
//     dispatch(likeVideo({ videoId, liked }));
//     return;
//     const { video, auth } = getState();
//     const selectedSections = video.selectedSections;
//     const section = {
//       user: auth.user._id,
//       video: video.currentVideo.id,
//       sections: selectedSections,
//       looptime: video.looptime,
//       label,
//       frequency,
//       sectionLabels: selectedSections.map(
//         (index) => video.currentVideo.sections[index].label
//       ),
//       notes,
//       test: test || false,
//     };

//     console.log('👕 section to upload: ', section);

//     const response = await fetch(
//       `${
//         process.env.REACT_APP_BACKEND
//       }/api/v1/users/favorite/${videoId}/${!liked}`,
//       {
//         credentials: 'include', // include, *same-origin, omit
//       }
//     );
//     const filledResponse = await response.json();
//     // console.log(response);
//     if (!response.ok) {
//       console.log('in failed if');
//       alert('fail');
//     } else {
//       console.log({ filledResponse });
//       dispatch(
//         videoActions.addSavedSelections({
//           newSection: filledResponse.data,
//           practiceDictionary: filledResponse.practiceDictionary,
//         })
//       );

//       // addSectionHandler(section);
//     }
//   };
// }

export const authActions = authSlice.actions;
export default authSlice.reducer;
