import React from 'react';
import classes from './VideoBoxAndThumb.module.css';
import Badge from './Badge';
import { IonIcon } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { ellipse } from 'ionicons/icons';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { videoActions } from '../store/video';
import BadgeList from './BadgeList';
import ThumbAndTitle from './ThumbAndTitle';
import VideoInfoWrapper from './VideoInfoWrapper';
import VideoClickWrapper from './VideoClickWrapper';
import VideoTitleBlock from './VideoTitleBlock';
import { formatTime } from './video_components/format';
import moment from 'moment-timezone';
import ThumbnailBadge from './ThumbnailBadge';
import { useSelector } from 'react-redux';

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const VideoBoxAndThumb = ({
  passKeyThrough,
  title,
  type,
  videoData,
  onTreeVideoClickHandler,
  selection,
  selectionLabels,
  removeSectionHandler,
  addSearchObject,
  isNew,
  favorited,
  videoTest = false,
}) => {
  const dispatch = useDispatch();
  const videoThumbnail = videoData.thumbnail;

  // console.log({ videoData });

  return (
    <VideoInfoWrapper
      // key={key + '_wrap'}
      passKeyThrough={passKeyThrough}
      id={videoData.id}
      videoData={videoData}
      selection={selection}
    >
      <VideoClickWrapper
        id={videoData.id}
        videoData={videoData}
        selection={selection}
        onTreeVideoClickHandler={onTreeVideoClickHandler}
      >
        <ThumbAndTitle
          videoThumbnail={videoThumbnail}
          videoTest={videoTest}
          videoData={videoData}
        >
          <div className={classes['duration-label']}>
            {videoData.duration ? formatTime(videoData.duration) : 'null'}
          </div>
          {isNew && <ThumbnailBadge text="new" />}
        </ThumbAndTitle>
      </VideoClickWrapper>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      > */}

      {/* <div className={classes['date-container']}>Mon</div> */}
      {/* </div> */}
      <VideoTitleBlock
        title={title}
        studio={videoData.studio}
        addSearchObject={addSearchObject}
        icon={videoData.studio?.icon}
        timeElapsed={
          <div className={classes['date-container']}>
            {timeAgo(videoData.dateRecorded)}
            {/* {formatDate(videoData.dateRecorded)} */}
          </div>
        }
        badges={
          <BadgeList
            danceStyle={videoData.style}
            primary={videoData.primaryKeywords}
            secondary={videoData.secondaryKeywords}
          />
        }
      />
      {/* <BadgeList
        danceStyle={videoData.style}
        primary={videoData.primaryKeywords}
        secondary={videoData.secondaryKeywords}
      /> */}
    </VideoInfoWrapper>
  );
};

export default VideoBoxAndThumb;

function formatDate(dateString) {
  // Create a new Date object from the input string
  const date = new Date(dateString);
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const dayOfWeek = weekdays[date.getUTCDay()];
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${dayOfWeek}, ${month} ${day}, ${year}`;
}

// Example usage
// const formattedDate = formatDate('2024-04-01');
// console.log(formattedDate); // Output: 'Mon, Apr 1, 2024'

export function timeAgo(dateString) {
  const date = new Date(dateString);

  const localTimeZone = moment.tz(timeZone);
  let endOfDay = localTimeZone
    .clone()
    .set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    })
    .tz('UTC');
  const endOfDayDate = new Date(endOfDay);

  const diffInSeconds = Math.floor((endOfDayDate - date) / 1000);

  const units = [
    { name: 'year', seconds: 31536000 },
    { name: 'month', seconds: 2592000 },
    { name: 'week', seconds: 604800 },
    { name: 'day', seconds: 86400 },
    { name: 'hour', seconds: 3600 },
    { name: 'minute', seconds: 60 },
    // { name: 'second', seconds: 1 }
  ];

  for (let unit of units) {
    const interval = Math.floor(diffInSeconds / unit.seconds);
    if (interval >= 1) {
      return `${interval} ${unit.name}${interval !== 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
}
