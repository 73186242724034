import React, { useState, useEffect } from 'react';
import classes from './TabPanel.module.css';
import { motion } from 'framer-motion';
import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { videoActions } from '../../store/video';
import { useSelector } from 'react-redux';
import TimerIcon from '@mui/icons-material/Timer';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SearchIcon from '@mui/icons-material/Search';
import LoopIcon from '@mui/icons-material/Loop';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import IconTextButton from '../Buttons/IconTextButton';

let count = 0;

export default function TabPanel({ tabIndex, remaining }) {
  console.log('🐝 tab panel rerender: ', count++, { tabIndex });
  const dispatch = useDispatch();
  const newVideoCount = useSelector(
    (state) => state.auth.user?.newVideos?.length
  );

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 3,
      // top: 0,
      // border: `2px solid var(--background-light-gray)`,
      padding: '0 4px',
      background: `${
        'red'
        // 'var(--main-purple-no-transparancy)'
        // 'var(--accent)'
        // tabIndex === 1
        // ? 'var(--main-purple-no-transparancy)' : 'var(--text-dark-gray)'
      }`,
      color: 'var(--background-light-gray)',
    },
  }));

  // const [blockBadge, setBlockBadge] = useState(-2);
  // useEffect(() => {
  //   // if (newVideoCount) {
  //   // setBlockBadge(false);
  //   // }
  //   setInterval(() => {
  //     setBlockBadge((prev) => prev + 1);
  //   }, 1000);
  // }, []);

  return (
    <div className={classes['outer-container']}>
      <div
        className={classes['tab']}
        highlight={String(tabIndex === 1)}
        onClick={() => {
          dispatch(videoActions.updateTabIndex(1));
        }}
      >
        {/* <StyledBadge
          badgeContent={remaining}
          // style={{ backgroundColor: 'var(--main-purple-no-transparancy)' }}
        > */}
        <div>Practice</div>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '11px',
          }}
        >
          <LoopIcon />
          <div style={{}}>Practice</div>
        </div> */}
        {/* </StyledBadge> */}
        {tabIndex === 1 && (
          <motion.div
            layoutId="tabSelector"
            className={classes['underline-tab']}
          />
        )}
      </div>
      <div
        className={classes['tab']}
        highlight={String(tabIndex === 2)}
        onClick={() => {
          dispatch(videoActions.updateTabIndex(2));
        }}
      >
        {newVideoCount ? (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            // transition={{ type: 'spring', stiffness: 300 }}
            className={classes['tab-badge-container']}
          >
            <StyledBadge
              // badgeContent={blockBadge || 7}
              badgeContent={newVideoCount}

              // invisible={blockBadge}
              // invisible={blockBadge === 0}
              // style={{ backgroundColor: 'var(--main-purple-no-transparancy)' }}
            />
          </motion.div>
        ) : (
          <></>
        )}
        <div>Videos</div>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '11px',
          }}
        >
          <OndemandVideoIcon />
          <div style={{}}>Videos</div>
        </div> */}
        {tabIndex === 2 && (
          <motion.div
            layoutId="tabSelector"
            className={classes['underline-tab']}
          />
        )}
      </div>
      <div
        className={classes['tab']}
        highlight={String(tabIndex === 3)}
        onClick={() => {
          dispatch(videoActions.updateTabIndex(3));
        }}
      >
        <div>Search</div>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '11px',
          }}
        >
          <SearchIcon />
          <div style={{}}>Search</div>
        </div> */}
        {tabIndex === 3 && (
          <motion.div
            layoutId="tabSelector"
            className={classes['underline-tab']}
          />
        )}
      </div>

      {/* <IconTextButton
        muiIcon={
          <OpenInFullOutlinedIcon
            style={{ fontSize: '25px', color: 'black' }}
          />
        }
        text="Crop"
        className="header-buttons"
        onClick={() => {
          // setShowSlider(true);
          // console.log(looptime[0] / 60);
          // onSeek(undefined, (looptime[0] / 60) * 100);
        }}
      /> */}
    </div>
  );
}
