import styledEngine from '@mui/styled-engine';
import React from 'react';
import classes from './Badge.module.css';

const maxLen = 10;
const maxWordLen = 4;

const Badge = ({ keyword, color = 'white', style = {} }) => {
  let modifiedKeyword;
  if (keyword.length > maxLen) {
    modifiedKeyword = trimLengths(keyword, true);
  } else {
    modifiedKeyword = trimLengths(keyword);
  }

  return (
    <div style={{ background: color, ...style }} className={classes.badge}>
      {/* {keyword.toUpperCase()} */}
      {modifiedKeyword}
    </div>
  );
};

// function capitalize(sentence) {
//   return sentence
//     .trim()
//     .split(' ')
//     .map((word) => word[0].toUpperCase() + word.slice(1))
//     .join(' ');
// }

function trimLengths(sentence, trim = false) {
  return (
    sentence
      .trim()
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ')
      .slice(0, maxLen) + (trim ? '..' : '')
  );
}

export default Badge;
